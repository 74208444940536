.home-section {
  padding: 40px 0;
}
.home-section-header {
  font-size: 22px;
  font-weight: bold;
  color: var(--clr-brown);
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  padding: 15px 0;
  border-top: 1px solid var(--clr-darkbrown);
  border-bottom: 1px solid var(--clr-darkbrown);
  cursor: pointer;
}
.home-section-content {
  margin-top: 10px;
}
.home-section-content-header {
  font-size: 20px;
  color: var(--clr-darkbrown);
  font-weight: bold;
  margin: 20px 0;
}
.home-section-content-body {
  margin: 10px 0;
  text-align: justify;
}
.home-section-content-list li {
  margin-left: 50px;
}
.home-section-header-desc {
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
  font-style: italic;
}
.home-section-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 50px;
}
.home-section-card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.home-section-card-header-title {
  margin-left: 15px;
  font-size: 22px;
  text-transform: uppercase;
  color: var(--clr-darkbrown);
  font-weight: bold;
}
.home-section-card-body {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .home-section-cards {
    grid-template-columns: 1fr;
  }
  .home-section-card-header {
    justify-content: center;
  }
}
