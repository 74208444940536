.about {
  margin: 20px 0;
}
.about-hero {
  width: 100%;
  height: 100%;
  position: relative;
}
.about-hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 10px 0;
}
.about-section {
  margin: 10px 0;
}
.about-section p {
  margin: 20px 0;
}
.about-section-table {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px 0 0 0;
  margin: 10px 0;
}

.about-section li {
  margin: 5px 0;
}

.about-hero h1 {
  text-align: center;
  text-transform: uppercase;
}
