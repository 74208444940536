.quote {
  margin-bottom: 20px;
}
.quote h1 {
  text-align: center;
  margin: 20px 0;
  text-transform: uppercase;
}
.quote p {
  margin: 20px 0;
}
.quote-img {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.quote-img img {
  max-width: 80%;
  height: 700px;
  object-fit: cover;
  margin: 10px 0;
}
.quote-img p {
  margin: 10px 0;
  font-style: italic;
}
.quote-header h1 {
  color: var(--clr-brown);
  text-align: left;
}
.quote-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.quote-table tr {
  text-align: left;
}
.quote-table td,
.quote-table th {
  border: 1px solid #ccc;
  padding: 5px;
}
.image-fuild {
  width: 100%;
  height: 100%;
  padding: 10px 0;
}
.image-fuild img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .quote-img img {
    max-width: 80%;
    height: 400px;
  }
}
