@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

:root {
  --clr-brown: #c97725;
  --clr-darkbrown: #844d15;
  --clr-black: #000;
  --clr-white: #fff;
}

.separate {
  background: var(--clr-darkbrown);
  display: block;
  height: 2px;
  width: 180px;
  overflow: hidden;
  margin: auto;
}

i {
  font-size: 25px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bold {
  font-weight: bold;
}
