.projects {
  margin: 20px 0;
}
.projects h1 {
  text-align: center;
  margin: 20px 0;
  text-transform: uppercase;
}
.projects-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px 10px;
}
.projects-card {
  width: 100%;
  height: 400px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.projects-card-img {
  width: 100%;
  height: 205px;
  object-fit: cover;
  border-radius: 5px;
}
.projects-card-name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-content: space-between;
  align-items: center;
  padding: 5px 10px;
  height: 195px;
  font-size: 20px;
  color: var(--clr-brown);
}
.projects-card button {
  padding: 10px 15px;
  margin: 30px 0;
  border-radius: 10px;
  border: 1px solid #000;
  background-color: #fafafa;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.projects-card button:hover {
  background-color: var(--clr-brown);
  color: #fff;
}
.projects-cards a {
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .projects-cards {
    grid-template-columns: 1fr;
  }
}
