.header-wrapper {
  display: grid;
  grid-template-columns: 270px 1fr 1fr;
  gap: 0 10px;
}
.header-logo {
  width: 250px;
  cursor: pointer;
}
.header-logo img {
  height: 100%;
  width: 100%;
  padding: 15px 0;
}
.header-contact,
.header-location {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 15px 0;
}
.header-contact-slogan,
.header-location-company {
  color: var(--clr-brown);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 22px;
  line-height: 25px;
}
.header-contact-phone a,
.header-location-address {
  text-decoration: none;
  color: var(--clr-black);
}
.header-location-company {
  font-size: 22px;
}
.header-bottom {
  margin-top: 10px;
  background-color: var(--clr-brown);
  color: var(--clr-white);
  padding: 10px 0;
}
.header-nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-brown);
}
.header-nav a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  text-decoration: none;
  color: var(--clr-white);
  font-weight: bold;
}
.header-hamburger {
  display: none;
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .header-wrapper {
    width: 100vw;
    padding: 10px;
  }
}
@media screen and (max-width: 768px) {
  .header-wrapper {
    grid-template-columns: 1fr 1fr;
    padding: 10px 30px;
  }
  .header-location {
    display: none;
  }
  .header-hamburger {
    display: block;
  }
  .header-nav {
    margin-top: 10px;
    flex-direction: column;
  }
  .header-nav a {
    padding: 15px 0;
  }
}
@media screen and (max-width: 480px) {
  .header-top .container {
    padding: 0;
  }
  .header-wrapper {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .header-logo {
    width: 100%;
  }
  .header-contact {
    align-items: center;
  }
}
