.project-detail {
  margin-bottom: 20px;
}
.project-name {
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
.project-content h1 {
  color: var(--clr-brown);
  margin: 10px 0;
}
.project-content p img {
  display: block;
  max-width: 80%;
  margin: 10px auto;
  text-align: center;
  height: 100%;
  object-fit: cover;
}
.project-content h2 {
  margin: 10px 0;
  color: var(--clr-darkbrown);
}

.project-content p {
  margin: 10px 0;
}
