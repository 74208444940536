.admin-container {
  margin: 20px;
}
.admin-content-header button {
  margin: 10px 0;
  padding: 10px 20px;
  border: 1px solid #000;
  background-color: #fafafa;
  cursor: pointer;
  border-radius: 5px;
}
.admin-content-header button:hover {
  background-color: var(--clr-brown);
  color: #fff;
}
.admin-content-form {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 20px auto;
}
.admin-content-form label {
  margin: 10px 0;
}
.admin-content-form input {
  margin: 10px 0;
  padding: 10px;
}
.admin-content-form-function {
  margin: 50px 0;
  display: flex;
  justify-content: space-between;
}
.admin-content table {
  border-collapse: collapse;
  width: 100%;
}
.admin-content td,
.admin-content th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  max-width: 60vw;
  overflow: hidden;
}
.admin-content td button {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #000;
}
.searchInData {
  margin: 10px 0 20px 0;
  width: 360px;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #000;
}
.pagination {
  display: flex;
  list-style-type: none;
  justify-content: end;
  margin: 10px 0;
}
.pagination li {
  margin-left: 10px;
}
.pagination li button {
  padding: 10px;
  cursor: pointer;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 5px;
}
