.admin-header {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ccc;
}
.admin-header-logo img {
  max-width: 160px;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.admin-header-nav a {
  padding: 10px 20px;
  color: #000;
  text-decoration: none;
}
.admin-header-nav a:hover {
  text-decoration: underline;
  text-underline-offset: 10px;
}
