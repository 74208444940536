.mySwiper1 {
  margin-top: 20px;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiper1 .swiper-slide img {
  display: block;
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 10px;
  transition: 0.3s ease;
  cursor: pointer;
}
.mySwiper1 .swiper-slide img:hover {
  filter: brightness(0.5);
}
