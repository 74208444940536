.login {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.login-form {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  padding: 20px;
  border: 1px solid #000;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-width: 300px;
}
.login-form h1 {
  padding: 20px 0;
  border-bottom: 1px solid #000;
}

.login-form label {
  margin: 10px 0;
}

.login-form input {
  width: 100%;
  border: 1px solid #000;
  outline: none;
  padding: 5px 10px;
  border-radius: 5px;
}

.login-form button {
  margin: 20px 0;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  background-color: var(--clr-brown);
  color: #fff;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}
.login-form button:hover {
  background-color: var(--clr-darkbrown);
}
.error-msg {
  color: red;
  margin: 5px 0;
  font-size: 13px;
  padding: 0 5px;
}

.login-form input.error {
  border-color: red !important;
}
