.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
.image-f {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 10px 0;
}
.image-f img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .image-f {
    grid-template-columns: 1fr;
  }
}
