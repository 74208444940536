.footer {
  background-color: var(--clr-brown);
  color: var(--clr-white);
  padding: 20px 0;
}
.footer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.footer-grid-item span {
  font-size: 20px;
  text-transform: uppercase;
}
.footer-grid-item p {
  margin: 10px 0;
}
.footer-bottom {
  padding: 10px 0;
  background-color: var(--clr-darkbrown);
  color: var(--clr-white);
  text-align: center;
}

@media screen and (max-width: 768px) {
  .footer-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 480px) {
  .footer-grid {
    grid-template-columns: 1fr;
  }
}
