.error-page {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.error-page-container {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.error-page-container p {
  margin: 10px 0;
}
.error-page-container a {
  color: blue;
  text-decoration-style: wavy;
  text-underline-offset: 10px;
}
.error-page-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
