.contact {
  min-height: 80vh;
  margin: 20px 0;
}
.contact h1 {
  text-align: center;
  text-transform: uppercase;
}
.contact h2 {
  margin: 10px 0;
  font-size: 30px;
}
.contact-separate {
  width: 100%;
  height: 1px;
  background-color: var(--clr-darkbrown);
  margin: 20px 0;
}
.contact p {
  margin: 10px 0;
  font-size: 25px;
}
