.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.circle-loading {
  display: inline-flex;
  animation: loading 1.5s infinite;
}

.circle-loading div {
  width: 30px;
  height: 30px;
  border: 2px solid #fcb564;
  border-radius: 50%;
  margin: 0 5px;
}

@keyframes loading {
  50% {
    transform: rotate(200deg);
  }

  75% {
    transform: rotate(160deg);
  }

  100% {
    transform: rotate(180deg);
  }
}
